<template>
  <base-modal
    ref="upload"
    :class="$style['modal']"
    :title="importSettings[current].title"
    description="Import Wizard."
    :is-open="showModal"
    @close="showModal = false"
  >
    <div
      v-show="$refs.upload && $refs.upload.dropActive"
      :class="$style['drop-active']"
    >
      <div :class="$style['bordered-frame']"></div>
      <h3>drop here</h3>
    </div>
    <div>
      Refer to the
      <a
        href="https://help.piplanning.io/en/collections/603683-import-export"
        target="_blank"
        class="link"
      >
        documentation
      </a>
      to see how you need to format the file.
    </div>

    <template #actions>
      <file-upload
        ref="upload"
        class="next-btn valid"
        :value="files"
        :drop="true"
        @input="importFile"
      >
        <i class="fa fa-plus"></i>
        <span v-if="!hold">
          Choose {{ importSettings[current].extension }} file
        </span>
      </file-upload>
      <div v-if="error" class="error">{{ error }}</div>
    </template>
  </base-modal>
</template>

<script>
import { trackEvent } from "@/analytics/track";
import BaseModal from "../BaseModal.vue";
import SessionHandler from "@/handlers/sessionHandler";
import { mapActions, mapGetters } from "vuex";
import { safepiplanningsImportModalSeen } from "@/analytics/events";

export default {
  name: "ImportModal",
  components: { BaseModal },
  data() {
    return {
      importSettings: {
        backlog: {
          method: "import_csv",
          title: "CSV Import (Backlog Board)",
          extension: "csv",
        },
        team: {
          method: "import_team_csv",
          title: "CSV Import (Team Boards)",
          extension: "csv",
        },
        jalign: {
          method: "import_jalign_xlsx",
          title: "Jira Align xlsx import",
          extension: "xlsx",
        },
      },
      current: "backlog",
      csvSessionId: null,
      showModal: false,
      files: [],
      hold: false,
      error: "",
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
  },
  methods: {
    ...mapActions("toast", ["showMessage"]),
    sessionHandler(sessionId) {
      return new SessionHandler(this.$store.getters, sessionId);
    },
    show(session, type) {
      trackEvent(safepiplanningsImportModalSeen(type));

      this.error = "";
      this.csvSessionId = session;
      this.current = type;
      this.showModal = true;
    },
    importFile(value) {
      if (value.length > 0) {
        this.hold = true;
        const file = value[0].file;
        const parts = file.name.split(".");
        const ext = parts[parts.length - 1].toLowerCase();
        const permittedExt = this.importSettings[this.current].extension;
        if (ext !== permittedExt) {
          this.fail(`File type must be ${permittedExt}, but was ${ext}.`);
          this.hold = false;
          return false;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          const contentBase64 = e.target.result;
          if (contentBase64.length > 500000) {
            this.fail("File is too big (> 400kB)");
            this.hold = false;
            return false;
          }
          this.saveImportFile(contentBase64, file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    async saveImportFile(contentBase64, filename) {
      const method = this.importSettings[this.current].method;
      try {
        const res = await this.sessionHandler(this.csvSessionId).importFile(
          method,
          contentBase64
        );
        if (res.success) {
          this.showMessage({
            message: `File ${filename} has been imported.`,
          });
          this.showModal = false;
        } else {
          this.fail(res.error);
        }
      } catch (details) {
        this.fail(details);
      } finally {
        this.hold = false;
      }
    },
    fail(message) {
      this.error = message;
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  opacity: 0.9;
  text-align: center;
  background-color: $greyColor;

  .bordered-frame {
    width: 96%;
    height: 90%;
    position: absolute;
    left: 2%;
    top: 5%;
    box-sizing: border-box;
    border: 6px dashed $greyColor;
    border-radius: 10px;
  }

  h3 {
    margin: -0.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 70px;
    color: $whiteColor;
    padding: 0;
  }
}
</style>
